<template>
    <div class="survey-container has-text-primary">
        <div class="survey-inner-container">
            <div>
                <div class="survey-step">Étape 4/{{ userStore.surveyStepCount }}</div>
                <SurveyHeader>
                    <template #title>
                        <span class="survey-title">Comment est chauffée votre eau chaude&nbsp;?</span>
                    </template>
                </SurveyHeader>

                <!-- hotWaterSource field -->
                <SquareList :items="sources" :selected="[hotWaterSource.value]" @click-item="updateHotWaterSource"/>

                <div class="text-error mb-4" v-if="hotWaterSource.error">{{ hotWaterSource.error }}</div>


                <!-- otherClarification field -->
                <div v-if="hotWaterSource.value == 'other'">
                    <div class="survey-question">
                        Vous avez coché "Autres / Je ne sais pas", pouvez-vous préciser&nbsp;?
                    </div>

                    <div class="control">
                        <textarea
                            class="textarea input-border"
                            :class="{ 'dangerous': otherClarification.error }"
                            v-model="otherClarification.value"
                            type="text"
                            placeholder=""
                            name="otherClarification"
                            maxlength=2048
                        ></textarea>
                    </div>

                    <div v-if="otherClarification.error" class="text-error mb-4">{{ otherClarification.error }}</div>
                </div>
            </div>

            
            <!-- global errors and next page button -->
            <div class="has-text-centered mt-5 mb-5">
                <div class="text-error mb-2">
                    {{ error }}
                </div>

                <a
                    class="button classic"
                    :class="{'is-loading': isLoading}"
                    @click="done"
                >
                    Suivant
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { useUserStore } from '@/stores/user';
import API from '@/services/api';
import SurveyHeader from '@/components/survey/SurveyHeader.vue';
import SquareList from '@/components/survey/SquareList.vue';
import { processSurveyError, hotWaterSources } from '@/utils.js';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const isLoading = ref(false)

const error = ref(null)

const hotWaterSource = ref({ error: null, value: null })
const otherClarification = ref({ error: null, value: null })

const sources = [...hotWaterSources]

if (userStore.account.survey_data) {
    hotWaterSource.value.value = userStore.account.survey_data.hot_water_source || null
    if(hotWaterSource.value.value == 'elec') hotWaterSource.value.value = 'elec_water_heater'
    otherClarification.value.value = userStore.account.survey_data.hot_water_other_clarification || null
}

const updateHotWaterSource = (slug) => {
    hotWaterSource.value.value = slug
    if(slug != "other") otherClarification.value.value = null
}

const done = async() => {
    let payload = {
        'step4_done': true,
        'hot_water_source': hotWaterSource.value.value == 'elec_water_heater' ? "elec" : hotWaterSource.value.value,
        'hot_water_other_clarification': otherClarification.value.value
    }

    let hasError = false;

    if (hotWaterSource.value.value == null) {
        hotWaterSource.value.error = "Merci de sélectionner un moyen."
        hasError = true
    } else {
        hotWaterSource.value.error = null
    }
    if (hotWaterSource.value.value == "other" && (otherClarification.value.value == null || otherClarification.value.value.trim() == "")) {
        otherClarification.value.error = "Merci de préciser votre réponse."
        hasError = true
    } else {
        otherClarification.value.error = null
    }

    console.log('done', payload)

    if (!hasError) {
        try {
            isLoading.value = true
            let resp = await API.pushSurveyData(userStore.account.id, payload)
            if (resp.status == 200) {
                userStore.trackEvent('click_next_step', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
                await userStore.update_profile()
                if(Object.keys(userStore.account.survey_data).includes('step4_done')){
                    router.push({ name: "Survey5" })
                } else {
                    router.push({ name: "Survey4" })
                }
            }
        } catch (e) {
            isLoading.value = false
            error.value = processSurveyError(e)
            userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3], 'error_type': error.value})
        }
    } else {
        let errorSet = ({
            "hot_water_source": hotWaterSource.value.error || '',
            "other_clarification": otherClarification.value.error || ''
        })
        userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3], 'error_type': JSON.stringify(errorSet)})
    }
    isLoading.value = false
}

onMounted(() => {
    if(!Object.keys(userStore.account.survey_data).includes('step3_done')){
        router.push({ name: "Survey3" })
    }
})
</script>

<style scoped>

</style>
