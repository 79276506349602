<template>
    <div class="survey-container has-text-primary">
        <div class="survey-inner-container">
            <div>
                <div class="survey-step">Étape 7/{{ userStore.surveyStepCount }}</div>
                <SurveyHeader>
                    <template #title>
                        <span class="survey-title">Être alerté en cas de pic de consommation</span>
                    </template>
                    <template #sub>
                        <span>Une alerte par SMS pour baisser votre chauffage d'un degré, réduire votre facture et protéger l'environnement.</span>
                    </template>
                </SurveyHeader>

                <!-- phoneNumber field -->
                <div class="control has-icons-right mb-2">
                    <input
                        class="input input-border has-text-primary"
                        :class="{ 'dangerous': phoneNumber.error }"
                        style="padding-left: 1em;"
                        v-model="phoneNumber.value"
                        type="tel"
                        placeholder="Numéro de téléphone"
                    >
                </div>

                <div class="text-error mb-4" v-if="phoneNumber.error">{{ phoneNumber.error }}</div>
            </div>
            
            <div style="text-align: center;">
                <img src="/src/assets/img/illustration_phone.png" />
            </div>

            <!-- global errors and next page button -->
            <div class="has-text-centered mt-5 mb-5">
                <div class="text-error mb-2">
                    {{ error }}
                </div>
                
                <a
                    class="button classic mb-3"
                    :class="{'is-loading': isLoading}"
                    @click="done(true)"
                >
                    Activer les alertes SMS
                </a>
                <a
                    class="button is-outlined is-fullwidth height-46 radius-10 has-text-weight-bold has-text-primary"
                    style="border-color: var(--winter-blue-1);"
                    :class="{'is-loading': isLoading}"
                    @click="done(false)"
                >
                    Continuer sans alertes
                </a>
            </div>
        </div>
        <ModalPhoneOkayAlerts
            :active="showModalPhoneOkayAlerts"
            @close-modal="router.push({ name: 'Survey7' })"
        />
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, onMounted } from 'vue';
import API from '@/services/api';
import SurveyHeader from '@/components/survey/SurveyHeader.vue';
import { processSurveyError } from '@/utils.js';
import ModalPhoneOkayAlerts from "@/components/modals/ModalPhoneOkayAlerts.vue"

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const isLoading = ref(false)
const showModalPhoneOkayAlerts = ref(false)

const error = ref(null)

const phoneNumber = ref({ error: null, value: null })

if (userStore.account) {
    phoneNumber.value.value = userStore.account.phone_number || null
}

const isValidNumber = (string) => {
    const pattern = /^\+?\d{10,}$/;
    return pattern.test(string);
}

const done = async(okayAlerts) => {
    console.log("OKAY ALERTS", okayAlerts)

    let hasError = false;
    let hasOkValue = phoneNumber.value.value != null && isValidNumber(phoneNumber.value.value.replaceAll(" ", "").trim())

    if (okayAlerts && hasOkValue) {
        phoneNumber.value.error = null
    } else if (!okayAlerts) {
        phoneNumber.value.value = null
        phoneNumber.value.error = null
    } else {
        if (okayAlerts && !hasOkValue) {
            phoneNumber.value.error = "Vérifiez le format de votre n° de téléphone."
        }
        if (okayAlerts && (phoneNumber.value.value == null || phoneNumber.value.value.length == 0)) {
            phoneNumber.value.error = "Renseignez votre n° de téléphone pour activer les alertes."
        }
        hasError = true
    }

    let payload = {
        'phone_number': phoneNumber.value.value != null ? phoneNumber.value.value.replaceAll(" ", "").trim() : null,
        'phone_okay_alerts': okayAlerts
    }
    
    console.log('done', payload)
    console.log('hasError', hasError)

    if(!hasError){
        try {
            isLoading.value = true
            let resp = await API.pushPhoneData(userStore.account.id, payload)
            if (resp.status == 200) {
                userStore.trackEvent('click_next_step', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
                await userStore.update_profile()
                if(userStore.account.phone_number == payload.phone_number && userStore.account.phone_okay_alerts == okayAlerts){
                    if (userStore.account.phone_okay_alerts) {
                        // open modal
                        showModalPhoneOkayAlerts.value = true
                    } else (
                        router.push({ name: "Survey7" })
                    )
                } else {
                    router.push({ name: "Survey67phone" })
                }
            }
        } catch (e) {
            isLoading.value = false
            error.value = processSurveyError(e)
            userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3], 'error_type': error.value})
        }
    } else {
        let errorSet = ({
            "phone_number": phoneNumber.value.error || ''
        })
        userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3], 'error_type': JSON.stringify(errorSet)})
    }
    isLoading.value = false
}

onMounted(() => {
    if(!Object.keys(userStore.account.survey_data).includes('step6_done')){
        router.push({ name: "Survey6" })
    }
})
</script>

<style scoped>

</style>
