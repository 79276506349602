import axios from 'axios';
import API from '@/services/api';
import { defineStore } from 'pinia';
import { useAlertsStore } from './alert';
import { deepCopy, partersReadableNames } from '@/utils';

const ecowatt_showing_values = [2, 3]

export const useUserStore = defineStore('user', {
    state: () => ({
        surveyStepCount: 8,
        isAuthenticated: false,
        account: null,
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        selectedBanAddress: null,
        prmFound: null,
        externalParams: {},
        winterRoot: null
    }),
    getters: {
        consumedEnergy: (state) => {
            let gasTotal = 0
            let consoTotalGasInEuros = 0
            if (Object.keys(state.account.gas_diagnostic_indicators).length > 0) {
                gasTotal = state.account.gas_diagnostic_indicators.conso_total_avant_telechargement
                consoTotalGasInEuros = gasTotal * state.account.gas_price
            }
            return Math.floor((state.account.diagnostic_indicators.conso_total_avant_telechargement * state.account.elec_price) + consoTotalGasInEuros)
        },
        isThermostatProcess: (state) => {
            let ret = false
            if (state.account != null && (state.account.origin == 'thermostat-connecte' || state.account.process == 'thermostat-connecte')) {
                ret = true
            }
            return ret
        },
        externalParamsArray: (state) => {
            let origin = null
            let utm = null
            let partner = {}
            let process = null
            let externalParams = {...state.externalParams}
            if (externalParams != null) {
                if (Object.keys(externalParams).includes('origine')) {
                    origin = externalParams.origine
                    delete externalParams.origine
                }
                if (Object.keys(externalParams).includes('parcours')) {
                    process = externalParams.parcours
                    delete externalParams.parcours
                }
                if (Object.keys(externalParams).includes('partner_team_id')) {
                    partner['partner_team_id'] = externalParams.partner_team_id
                    delete externalParams.partner_team_id
                }
                if (Object.keys(externalParams).includes('partner_user_id')) {
                    partner['partner_user_id'] = externalParams.partner_user_id
                    delete externalParams.partner_user_id
                }
                utm = Object.keys(externalParams).length > 0 ? externalParams : null
            }
            if (Object.keys(partner).length == 0) partner = null
            return [origin, utm, partner, process]
        },
        hasPartnerCheckbox: (state) => {
            let ret = false
            if (state.externalParams != null) {
                if (state.externalParams['partner_user_id'] != undefined && state.externalParams['partner_user_id'] != '') {
                    ret = true
                }
            }
            return ret
        },
        hasGas: (state) => {
            let ret = false
            // If theses conditions are changed, go down in this file
            // to function update_profile to update same conditions
            if ((state.account.survey_data.heat_type == 'individual' && 
                state.account.survey_data.heat_sources.includes('gas')) || 
                state.account.survey_data.hot_water_source == 'gas'
            ) {
                ret = true
            }
            return ret
        }
    },
    actions: {
        "setExternalParams": function (data) {
            let params = deepCopy(data)
            if(Object.keys(params).includes('origine')) {
                if(params["origine"] == "MaPetitePlanète") {
                    params["origine"] = "MaPetitePlanete"
                }
                if(params["origine"] == "thermostat-connecte") {
                    params["origine"] = null
                    params["parcours"] = "thermostat-connecte"
                }
                if (!Object.keys(partersReadableNames).includes(params["origine"]) && Object.keys(params).includes('partner_user_id')) {
                    delete params['partner_user_id']
                }
            }
            this.externalParams = params
        },
        "set_account": function (data) {
            if (!data.survey_data) {
                data.survey_data = {}
            }
            this.account = data
        },
        "login": async function (user, pass) {
            let resp = await API.login(user, pass)
            // console.log('login', resp.data)
            this.update_tokens(resp.data.access, resp.data.refresh)
            this.isAuthenticated = true
            resp = await API.myProfile()
            // console.log(resp.data)
            this.account = resp.data.account
            this.$router.push({ name: "HomeRouter" })
        },
        "update_profile": async function () {
            let resp = await API.myProfile()
            const alertsStore = useAlertsStore()
            // console.log(resp)
            this.isAuthenticated = true
            if (resp.data.account.origin != null || resp.data.account.utm != null) {
                this.setExternalParams({'origine': resp.data.account.origin, ...resp.data.account.utm })
            }

            let accountId = resp.data.account.id
            if (resp.data.account.incomplete_experience_on_app){
                if (resp.data.account.incomplete_experience_on_app.user_with_too_few_data) {
                    alertsStore.addAlert('fewData', accountId)
                } else {
                    alertsStore.removeAlert('fewData')
                }
                if (resp.data.account.incomplete_experience_on_app.user_without_electric_heating) {
                    // A user without electric heating has few data so display just one alert
                    alertsStore.removeAlert('fewData')
                    alertsStore.addAlert('noHeat', accountId)
                } else {
                    alertsStore.removeAlert('noHeat')
                }
            }

            //- Alerts for gas process
            // We ckeck first if user has filled the survey
            if (resp.data.account.survey_data &&
                Object.keys(resp.data.account.survey_data).includes('heat_sources') &&
                Object.keys(resp.data.account.survey_data).includes('hot_water_source') &&
                Object.keys(resp.data.account.survey_data).includes('cooking_devices')
            ) {
                // If user has declared gas usage
                if ((resp.data.account.survey_data.heat_type == 'individual' && 
                    resp.data.account.survey_data.heat_sources.includes('gas')) || 
                    resp.data.account.survey_data.hot_water_source == 'gas'
                ) {
                    console.log("update_profile user declared gas usage")
                    // If user has no gas data yet
                    if (!resp.data.account.has_gas_data) {
                        console.log("update_profile user has no gas data yet")
                        // If user did homeys process without error
                        if (resp.data.account.homeys_status == 'ok') {
                            alertsStore.removeAlert('homeysError')
                            console.log("Homeys status OK")
                            // If user didn't click on grantingGrdf alert button 
                            // to say that he granted grdf
                            if (resp.data.account.granting_grdf == null) {
                                if (resp.data.account.homeys_signup_time != null) {
                                    let quarterHourInSeconds = 15 * 60
                                    let difference = Math.abs((Date.now() / 1000) - resp.data.account.homeys_signup_time)
                                    // change the alert after 15 minutes
                                    if (difference < quarterHourInSeconds) {
                                        console.log("checkEmailGrdf ")
                                        alertsStore.addAlert('checkEmailGrdf', accountId)
                                    } else {
                                        console.log("grantingGrdf ")
                                        alertsStore.addAlert('grantingGrdf', accountId)
                                    }
                                }
                            }
                            // If user clicked on grantingGrdf alert button
                            else if (resp.data.account.granting_grdf) {
                                console.log("grantingGrdfTrue ")
                                alertsStore.removeAlert('grantingGrdf')
                                alertsStore.removeAlert('checkEmailGrdf')
                                alertsStore.addAlert('grantingGrdfTrue', accountId)
                            }
                        }
                        else if (resp.data.account.homeys_status == 'ko') {
                            console.log("Homeys status KO")
                            // It's 'abord-step-1' and 'abord-step-2' in homeys doc
                            if (['abord-step-1', 'abord-step-2', 'abort-step-1', 'abort-step-2'].includes(resp.data.account.homeys_error_code)) {
                                console.log("Homeys aborted step 1 or 2")
                                alertsStore.addAlert('homeysProcessAborted', accountId)
                            } else (
                                alertsStore.addAlert('homeysError', accountId)
                            )
                        }
                    }
                    else {
                        alertsStore.removeAlert('grantingGrdfTrue')
                        alertsStore.removeAlert('grantingGrdf')
                        alertsStore.removeAlert('checkEmailGrdf')
                        alertsStore.removeAlert('homeysError')
                        alertsStore.removeAlert('homeysProcessAborted')
                    }
                }
            }
            this.account = resp.data.account
            this.winterRoot = resp.data.winter_api_root
        },
        "update_tokens": function (access, refresh) {
            localStorage.setItem('access_token', access)
            localStorage.setItem('refresh_token', refresh)
            this.access_token = access
            this.refresh_token = refresh
        },
        "do_refresh_token": async function () {
            try {
                let response = await axios.post(`${BACKEND_ROOT}/login/token/refresh/`, { refresh: localStorage.getItem('refresh_token') })
                this.update_tokens(response.data.access, localStorage.getItem('refresh_token'))
            } catch (error) {
                console.log("refresh_token failed")
                // context.dispatch("logout")
            }
        },
        "logout": async function () {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            this.access_token = null
            this.refresh_token = null
            this.isAuthenticated = false
            this.$router.push({ name: "SignUp" })
        },
        "getTcUrl": function (from) {
            let email = encodeURIComponent(this.account.user.username)
            let firstName = encodeURIComponent(this.account.enedis_first_name)
            let lastName = encodeURIComponent(this.account.enedis_last_name)
            let street = encodeURIComponent(this.account.enedis_address)
            let zipcode = encodeURIComponent(this.account.enedis_zipcode)
            let city = encodeURIComponent(this.account.enedis_city_name)
            let source = from == 'action' ? "action-tc" : "post-sign-up-parcours-tc"
            let prm = encodeURIComponent(this.account.enedis_prm)
            let data = `email=${email}&firstname=${firstName}&lastname=${lastName}&street=${street}&zipcode=${zipcode}&city=${city}&source=${source}&prm=${prm}`
            
            // console.log("TC_URL", this.winterRoot + "/redirect?destination=tc-eligibility&" + data)
            return this.winterRoot + "/redirect?destination=tc-eligibility&" + data
        },
        "want_free_thermostat": function (from) {
            // Send to eligibility survey
            let url = this.getTcUrl(from)
            window.location.href = url;
        },
        "get_eco_watt_daily_status": async function () {
            let resp = await API.getEcoWattDailyStatus()
            let isEcoWattDay = false
            let statusNumber = -1
            if (resp.status == 200 ) {
                statusNumber = resp.data
                // if today signal is in ecowatt_showing_values, this is an ecowatt day
                if (ecowatt_showing_values.includes(resp.data)) isEcoWattDay = true;
            }
            return {"isEcoWattDay": isEcoWattDay, "statusNumber": statusNumber }
        },
        "hasValidToken": async function () {
            let ret = false
            try {
                let response = await axios.post(`${BACKEND_ROOT}/login/token/verify/`, { token: localStorage.getItem('refresh_token') })
                if (response.status == 200) ret = true 
                console.log("VERIFY TOKEN", response.status)
            } catch (error) {
                console.log("verify_token failed")
            }
            return ret
        },
        "trackEvent": function (event_name, payload) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': event_name,
                ...payload
            });
            console.log("dataLayer", window.dataLayer)
        },
        "endingSurvey": function () {
            console.log("ENDING SURVEY STORE", this)
            API.endingSurveyData(this.account.id).then((resp) => {
                if (resp.status == 200) {
                    console.log("!!! 200 !!!")
                    this.trackEvent('finish_onboarding', {'origine': this.externalParamsArray[0], 'parcours': this.externalParamsArray[3]})
                    this.update_profile()
                    console.log("AFTER ENDING SURVEY check survey_done", this.account.survey_done)
                }
            })
        }
    }
})
